/* eslint-disable */
import HttpService from '../HttpService';
export default class UserService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`security/user`);
  }

  async reloadSessionByToken(token) {
    return this._httpService.get('/reloadSessionByToken/'+ token);
  }

}
