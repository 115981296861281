<template>
  <v-container>
    <genericDialog
      :dialog-on="inviteDialog.dialogOn"
      :bg-image="inviteDialog.bgImage"
      :btn-text="inviteDialog.btnText"
      :dialog-text="inviteDialog.dialogText"
      :dialog-title="inviteDialog.dialogTitle"
      :toggle-dialog="toggleInviteDialogDialog"
      :btn-action="toggleInviteDialogDialog"
    />
    <v-col style="margin-left: 10px; padding-bottom: 23px;">
      <BackArrow class="mt-2" :route="'root'"/>
    </v-col>

    <v-form
      id="formcadastro"
      ref="formcadastro"
      @submit.prevent="salvar"
      :lazy-validation="true"
    >
      <v-container v-if="!hasCPF">
        <v-row align="center" align-content="center" justify="center">
          <v-col cols="12" class="text-left main-title">
            Informe seu cpf para localizarmos sua empresa
          </v-col>
          <v-col cols="12">
            <v-text-field
              name="cpf"
              id="cpf"
              placeholder
              type="text"
              v-model="fields.cpf"
              :rules="cpfRules"
              :validate-on-blur="false"
              v-mask="$util.formmasks.cpf"
            />
          </v-col>

          <v-col cols="12">
            <generic-btn
                style="font-weight: bold"
                class="generic-title"
                :btn-props="{
                  block: true,
                  large: true,
                  loading: btnLoading,
                  disabled: !fields.cpf,
                  type: 'submit',
                  form: 'formcadastro'
                }"
            >
              Localizar
            </generic-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-else-if="!hasBeneficiary">
        <v-col class="text-left main-title">
          CPF não encontrado
        </v-col>
        <v-col cols="12" class="main-text text-left">
          Não foram encontrados dados com o CPF informado. Entre em contato com o suporte do sistema para mais informações.
        </v-col>
      </v-container>
      <span v-else-if="!showDependents">
        <v-container>
          <v-col cols="12" class="text-left">
            <p class="main-title">Conectar Empresa</p>
            <p class="main-text">
              Selecione a empresa que você deseja conectar e ter acesso aos seus benefícios
            </p>
          </v-col>
        </v-container>
        <v-flex xs12 v-for="connectCompany in connectCompanies" :key="connectCompany.financialGroup.id">
          <v-container>
            <generic-card style="padding: 10px" :title="connectCompany.financialGroup.name">
              <v-row align="center" align-content="center" justify="center">
                <v-col style="padding-right: 0; padding-top: 0" cols="1">
                  <pulseIcon :color="connectCompany.status ? getStatusColor(connectCompany.status) : 'red'" />
                </v-col>

                <v-col cols="11">
                  <p style="margin-bottom: 10px" class="text-left status-text">
                    <b>{{ connectCompany.status ? getStatusTraducao(connectCompany.status) : 'Você ainda não se conectou com esta empresa. Clique em Solicitar Conexão.' }}</b>
                  </p>
                </v-col>
              </v-row>

              <v-card-actions style="margin-top: 20px">
                <v-spacer />
                <generic-btn
                  :btn-props="{ rounded: true,  font: 'bold'  }"
                  v-if="isStatusAtivo(connectCompany.status)"
                  color="secondary"
                  :defaultTheme="false"
                  class="generic-title"
                  style="width: 140px; font-weight: bold"
                  :on-click="() => disconnectFromCompany(connectCompany.connectCompany.id)"
                >
                  Desconectar
                </generic-btn>
                <generic-btn
                  v-if="!connectCompany.status"
                  color="secondary"
                  class="generic-title"
                  :defaultTheme="false"
                  :btn-props="{ rounded: true, font: 'bold' }"
                  style="width: 170px; font-weight: bold"
                  :on-click="() => connectWithCompany(connectCompany.financialGroup.id)"
                >
                  Solicitar Conexão
                </generic-btn>
              </v-card-actions>
            </generic-card>
          </v-container>
        </v-flex>
      </span>
      <v-container class="pt-5" v-if="showDependents && dependents.length > 0">
        <v-col v-if="!enableSendInvite" cols="12" class="text-left pl-1">
          <v-row align="center" align-content="center" justify="center">
            <v-col style="padding-right: 0;" cols="1">
              <pulseIcon />
            </v-col>

            <v-col style="padding-left: 8px" cols="11">
              <p style="margin: 0" class="main-strong-text" v-for="connectCompany in connectCompanies" :key="connectCompany.financialGroup.id">
                Você está conectado à {{ connectCompany.financialGroup.name }}
              </p>
            </v-col>
          </v-row>
          <p class="main-title">Conectar Dependentes</p>
          <p class="main-text">Agora você pode conectar seus dependentes</p>
        </v-col>

        <Dependentes :reload="loadDependents" :dependents="dependents" :enable-send-invite="enableSendInvite" v-on:toggle-invite="toggleInvite"/>
      </v-container>
      <v-container v-if="showDependents && dependents.length === 0">
        <v-col cols="12" class="text-left">
          <p class="main-title">Empresa Conectada</p>
        </v-col>
        <generic-card
          :key="connectCompany.financialGroup.id"
          v-for="connectCompany in connectCompanies"
          style="padding: 10px"
          :title="connectCompany.financialGroup.name"
        >
          <v-row align="center" align-content="center" justify="center">
            <v-col style="padding-right: 0; padding-top: 0" cols="1">
              <pulseIcon :color="connectCompany.status ? getStatusColor(connectCompany.status) : 'green'" />
            </v-col>
            <v-col style="padding-left: 10px" cols="11">
              <p style="margin-bottom: 10px" class="text-left status-text">
                <b>{{ connectCompany.status ? getStatusTraducao(connectCompany.status) : 'Conexão Aceita' }}</b>
              </p>
            </v-col>
          </v-row>
        </generic-card>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
  import { mapMutations } from "vuex";
  import $util from "../../common/util";
  import { updateUser } from "../../common/securityserver";
  import {
    findByCPFGroupingByFinancialGroup,
    findByPhysicalPersonIdGroupingByFinancialGroup,
    requestConnectionWithCompany,
    disconnectFromCompany,
  } from "../../common/euprotegido-service";
  import {
    existsBeneficiaryByCpf,
    loadDependentsByBeneficiaryCpf
  } from "../../common/contract-service"
  import ContractService from "../../common/ContractService";
  import Dependentes from "./Dependentes";
  import genericCard from "./../../components/generic-card/index.vue";
  import GenericBtn from "../../components/generic-btn";
  import pulseIcon from "../../components/pulse-icon/index.vue";
  import GenericDialog from "../../components/generic-dialog/index.vue";
  import BackArrow from "../../components/covid/back-arrow/index.vue"
  import UserService from "../../services/security/User";

  export default {
    components: {
      Dependentes,
      genericCard,
      pulseIcon,
      GenericBtn,
      GenericDialog,
      BackArrow
    },
    props: {
      dialog: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    computed: {
      showdialog: {
        get: function() {
          return this.dialog;
        },
        set: function(value) {
          this.$emit("update:dialog", value);
        }
      }
    },
    data() {
      return {
        fields: {
          cpf: "",
        },
        describe : "",
        hasCPF: false,
        hasBeneficiary: true,
        rules: [],
        btnLoading: false,
        cpfRules:[
          v => !v || $util.validateCPF(v) || 'CPF inválido',
        ],
        connectCompanies : [],
        showDependents : "",
        enableSendInvite: false,
        dependents: [],
        inviteDialog: {
          dialogOn: false,
          bgImage: '/img/bg-dialogs/bg-dialog-convidar-dependente.png',
          dialogTitle: 'Solicitação enviada',
          dialogText: 'Em breve você poderá aproveitar todos os seus benefícios.',
          btnText: 'Ok',
        },
      };
    },
    async created() {
      this.fields.cpf = this.$auth.user().cpf
      this._contractservice = new ContractService();
      this._userService = new UserService();
      await this.loadDescribe();
      await this.reloadSessionByToken()
    },
    mounted() {
      this.hasCPF = this.$auth.user().cpf != null;
      if (this.hasCPF) {
        this.loadConnectCompanies();
        this.loadDependents();
      }
    },
    methods: {
      ...mapMutations(["showmsg", "loading", "showDefaultErrorMsg", "refreshReloadableKey"]),
      closeDialog() {
        this.showdialog = false;
      },
      toggleInviteDialogDialog() {
        this.inviteDialog.dialogOn = !this.inviteDialog.dialogOn;
      },
      backPage() {
        if(this.enableSendInvite) {
          this.toggleInvite();
        }else {
          this.$router.push('/');
        }
      },
      toggleInvite() {
        this.enableSendInvite = !this.enableSendInvite;
      },
      isStatusAtivo(connectCompany){
        return connectCompany == 'ACTIVE'
      },
      getStatusTraducao(status){
        let traducao = "";

        if(status == 'ACTIVE')
          traducao = 'Conectado';
        else if(status == 'PENDING')
          traducao = 'Pendente';
        else if(status == 'INACTIVE')
          traducao = 'Inativo';
        else if(status == 'ACCEPT')
          traducao = 'Aceito';
        else if(status == 'REFUSED')
          traducao = 'Recusado';

        return traducao;
      },
      getStatusColor(status){
        let traducao = "";

        if(status == 'ACTIVE')
          traducao = 'green';
        else if(status == 'PENDING')
          traducao = 'yellow';
        else if(status == 'INACTIVE')
          traducao = 'red';
        else if(status == 'ACCEPT')
          traducao = 'green';
        else if(status == 'REFUSED')
          traducao = 'red';

        return traducao;
      },
      async loadDescribe() {
        await this._contractservice.FindbyDescribe();
        this.describe = JSON.parse(sessionStorage.getItem("describe-contract"));
      },
      getdescribe(name, campo) {
        return this._contractservice.dsDescribe(this.describe, name, campo);
      },
      disconnectFromCompany(connectCompanyID){
        this.loading(true);
        disconnectFromCompany(connectCompanyID).then(res => {
          this.showmsg({ text: "Solicitação efetuada com sucesso!", type: "success" });
          this.loading(false);
        }).catch(err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
          this.loading(false);
        })
      },
      async connectWithCompany(financialGroupId){
        this.loading(true);

        const data = {
          financialGroupId,
          cpf: this.$auth.user().cpf,
          userID : this.$auth.user().id
        }

        requestConnectionWithCompany(data).then( res => {
          this.connectCompanies.forEach(function (c) {
            if (c.financialGroup.id == financialGroupId) {
              c.connectCompanyOrder = res.data;
              c.status = res.data.status;
            }
          });

          this.reloadSessionByToken()
          //this.$router.push("/conect
          //this.$router.push("/conectarComSuaEmpresa");
        }).catch( err => {
          this.showmsg({ text: "Erro ao conectar com a empresa!", type: "error" });
        }).finally(() => {
          this.toggleInviteDialogDialog()
          this.loading(false);
        })
      },
      async reloadSessionByToken(){
        const token = this.$auth.getAccessToken()
        let reloadSessionResponse = await this._userService.reloadSessionByToken(token)

        try {
          if(reloadSessionResponse.data) {
            this.$auth.setUser(reloadSessionResponse.data.user)
            this.$auth.setCPF(this.$util.removerPontuacaoCPF(this.fields.cpf))
          }
        }catch(e){
            this.showmsg({ text: "Erro ao recarregar dados da sessão.", type: "error" });
            this.loading(false);
        }
      },
      loadConnectCompanies(){
        this.loading(true);
        this.checkIfBeneficiaryExists();


        if(this.$auth.user().physicalPersonId) {
          findByPhysicalPersonIdGroupingByFinancialGroup(this.$auth.user().physicalPersonId).then(res => {
            this.loadConnections(res.data);
          }).catch(err => {
            this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
          }).finally(() => {
            this.loading(false);
          });
        }else {
          findByCPFGroupingByFinancialGroup(this.$auth.user().cpf).then(res => {
            this.loadConnections(res.data);
          }).catch(err => {
            this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
          }).finally(() => {
            this.loading(false);
          });
        }
      },
      loadConnections(connections){
        let thiz = this;
        this.connectCompanies = connections;
        var connectCompanyAtivo = "";
        if(!this.connectCompanies) {return;}
        this.connectCompanies.forEach(function(connectCompany) {
          connectCompanyAtivo = connectCompany.connectCompanyConnection && connectCompany.connectCompanyConnection.status == "ACTIVE";

          connectCompany.status = connectCompany.connectCompanyConnection ? connectCompany.connectCompanyConnection.status :
                  connectCompany.connectCompanyRequest ? connectCompany.connectCompanyRequest.status : null;
          connectCompany.benefits = [];
          connectCompany.contracts.forEach(function(c) {
            var benefit =  c.policy + " - " + thiz.getdescribe(c.benefit, "Benefits__c") ;
            benefit += c.carrier ? "/" + c.carrier : "";
            connectCompany.benefits.push(benefit);
          });
        });
        this.showDependents = connectCompanyAtivo;
      },
      checkIfBeneficiaryExists(){
        existsBeneficiaryByCpf(this.$auth.user().cpf).then(res => {
          this.hasBeneficiary = (res.data == true);
        }).catch(err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
        })
      },
      loadDependents(){
        this.loading(true);

        loadDependentsByBeneficiaryCpf(this.$auth.user().cpf).then(res => {
          this.dependents = res.data;
          this.loading(false);
        }).catch(err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
          this.loading(false);
        })
      },
      salvar() {
        if (!this.$refs.formcadastro.validate()) {
          setTimeout(this.$refs.formcadastro.resetValidation, 3000);
          return;
        }

        let cpf = $util.removerPontuacaoCPF(this.fields.cpf);

        const user = {
          id : this.$auth.user().id,
          cpf
        }

        this.loading(true);

        updateUser(user).then(res => {
          this.$auth.setCPF(cpf);
          this.hasCPF = true;
          this.loadConnectCompanies();
          this.showmsg({ text: "Cadastro salvo!", type: "success" });
          this.loading(false);
        }).catch(err => {
          this.showmsg({ text: "Erro ao atualizar cadastro!", type: "error" });
          this.loading(false);
        })

      }
    }
  };
</script>
