<template>
  <v-card hover color="white" class="card-bg">
    <v-card-title style="padding-bottom: 0" v-if="title" class="card-title">
      {{ title }}
    </v-card-title>
    <v-container style="padding-top: 0; padding-bottom: 0">
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'GenericIndex',
  props: {
    title: {
      required: false,
      type: String
    }
  }
}
</script>

<style scoped>
.card-bg {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 11px !important;
  width: 90vw;
}

.card-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: var(--v-primary);
}
</style>